import React, {useState} from "react";
import parse from 'html-react-parser';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import {
    CheckboxForm,
    CheckboxWrapper,
    FormBenefits,
    FormBenefitsWrapper,
    Input,
    SelectForm
} from "../styled/forms/FormComponents";
import Col from "../styled/grid/Col";
import {Button} from "../styled/button/ButtonComponents";
import {Body} from "../styled/typography/Typography";

/*import CountriesEN from "../../../static/countries/en/countries.json";*/
/*import CountriesHU from "../../../static/countries/hu/countries.json";*/

const FormTrialGR = ({lang, state}) => {

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("");
    const [preferred_contact, setPreferred_contact] = useState("");
    const [product, setProduct] = useState(state ? state : "");
    const [subProduct, setSubProduct] = useState(state ? state : "");
    const [message, setMessage] = useState("");

    const [isError, setIsError] = useState(
        {
            firstname: {error: false, text: ""},
            lastname: {error: false, text: ""},
            email: {error: false, text: ""},
            country: {error: false, text: ""},
            phone: {error: false, text: ""},
        });

    function FormErrorHandler(props) {
        if (props === "firstname") {
            if (firstname === "") {
                setIsError({
                    ...isError,
                    firstname: {error: true, text: lang === "hu" ? "Kötelező mező." : "This field is required."}
                });
            } else {
                setIsError({...isError, firstname: {error: false, text: ""}});
            }
        } else if (props === "lastname") {
            if (lastname === "") {
                setIsError({
                    ...isError,
                    lastname: {error: true, text: lang === "hu" ? "Kötelező mező." : "This field is required."}
                });
            } else {
                setIsError({...isError, lastname: {error: false, text: ""}});
            }
        } else if (props === "email") {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (email === "") {
                setIsError({
                    ...isError,
                    email: {error: true, text: lang === "hu" ? "Kötelező mező." : "This field is required."}
                });
            } else if (!re.test(String(email).toLowerCase())) {
                setIsError({
                    ...isError,
                    email: {error: true, text: lang === "hu" ? "Hibás formátum." : "Invalid email."}
                });
            } else {
                setIsError({...isError, email: {error: false, text: ""}});
            }
        } else if (props === "country") {
            if (country === "") {
                setIsError({
                    ...isError,
                    country: {error: true, text: lang === "hu" ? "Kötelező mező." : "This field is required."}
                });
            } else {
                setIsError({...isError, country: {error: false, text: ""}});
            }
        } else if (props === "phone") {
            const re = /^\+[0-9]*$/;
            if (phone === "") {
                setIsError({
                    ...isError,
                    phone: {error: true, text: lang === "hu" ? "Kötelező mező." : "This field is required."}
                });
            } else if (!re.test(String(phone).toLowerCase())) {
                setIsError({
                    ...isError,
                    phone: {
                        error: true,
                        text: lang === "hu" ? "Helyes formátum: +36301234567" : "Required format is +36301234567."
                    }
                });
            } else {
                setIsError({...isError, phone: {error: false, text: ""}});
            }
        }
    }

    return (
        <FormBenefitsWrapper>
            <FormBenefits action="https://balasys.getresponse360.pl/add_subscriber.html" accept-charset="utf-8" method="post">
                <Input
                    id="first_name"
                    name="first_name"
                    type="text"
                    span={6}
                    variant="outlined"
                    size="small"
                    label={lang === "hu" ? "Vezetéknév:" : "First name:"}
                    required
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    error={isError.firstname.error}
                    helperText={isError.firstname.error ? isError.firstname.text : ""}
                    onBlur={() => FormErrorHandler("firstname")}
                />
                <Input
                    id="last_name"
                    name="last_name"
                    type="text"
                    span={6}
                    variant="outlined"
                    size="small"
                    label={lang === "hu" ? "Keresztnév:" : "Last name:"}
                    required
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    error={isError.lastname.error}
                    helperText={isError.lastname.error ? isError.lastname.text : ""}
                    onBlur={() => FormErrorHandler("lastname")}
                />
                <Input
                    id="custom_phone"
                    name="custom_phone"
                    type="text"
                    span={4}
                    variant="outlined"
                    size="small"
                    label={lang === "hu" ? "Telefonszám:" : "Phone number:"}
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    error={isError.phone.error}
                    helperText={isError.phone.error ? isError.phone.text : ""}
                    onBlur={() => FormErrorHandler("phone")}
                />
                <Input
                    id="email"
                    name="email"
                    type="text"
                    span={4}
                    variant="outlined"
                    size="small"
                    label="E-mail:"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={isError.email.error}
                    helperText={isError.email.error ? isError.email.text : ""}
                    onBlur={() => FormErrorHandler("email")}
                />
                <SelectForm variant="outlined" size="small" span={4} required>
                    <InputLabel
                        id="custom_country_label">{lang === "hu" ? "Ország:" : "Country:"}</InputLabel>
                    <Select
                        native
                        id="custom_country"
                        name="custom_country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        label={lang === "hu" ? "Ország:" : "Country:"}
                    >
                        <option aria-label="None" value=""/>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Aland Islands">Aland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cabo Verde">Cabo Verde</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, the democratic republic of the">Congo, the democratic republic of the
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curacao">Curacao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Territories">French Southern Territories</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic republic of">Iran, Islamic republic of</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of man">Isle of man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, democratic people's republic of">Korea, democratic people's republic of
                        </option>
                        <option value="Korea, republic of">Korea, republic of</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao people's democratic republic">Lao people's democratic republic</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, the former yugoslav republic of">Macedonia, the former yugoslav
                            republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, federated states of">Micronesia, federated states of</option>
                        <option value="Moldova, republic of">Moldova, republic of</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestine, State of">Palestine, State of</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Réunion">Réunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">Russian Federation</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Barthélemy">Saint Barthélemy</option>
                        <option value="Saint Helena, Ascension and Tristan da Cunha">Saint Helena, Ascension and Tristan
                            da Cunha
                        </option>
                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Martin">Saint Martin</option>
                        <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                        <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Sint Maarten">Sint Maarten</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and the South Sandwich Islands">South Georgia and the South
                            Sandwich Islands
                        </option>
                        <option value="South Sudan">South Sudan</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic Of">Tanzania, United Republic Of</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Vatican City State">Vatican City State</option>
                        <option value="Venezuela, Bolivarian Republic Of">Venezuela, Bolivarian Republic Of</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Virgin Islands, British">Virgin Islands, British</option>
                        <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                        <option value="Wallis and Futuna">Wallis and Futuna</option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                        {/*lang === "hu" && CountriesHU.map((item => (
                            <option value={item.alpha2} key={item.alpha2}>{item.name}</option>
                        )))}
                        {lang === "en" && CountriesEN.map((item => (
                            <option value={item.alpha2} key={item.alpha2}>{item.name}</option>
                        )))*/}
                    </Select>
                </SelectForm>
                <SelectForm variant="outlined" size="small">
                    <InputLabel
                        id="preffered_contact-label">{lang === "hu" ? "Hogyan kereshetjük önt?" : "Preferred communication"}</InputLabel>
                    <Select
                        native
                        name="custom_preffered_contact"
                        id="custom_preffered_contact"
                        value={preferred_contact}
                        onChange={(e) => setPreferred_contact(e.target.value)}
                        label={lang === "hu" ? "Hogyan kereshetjük önt?" : "Preferred communication"}
                    >
                        <option aria-label="None" value=""/>
                        <option value="phone">{lang === "hu" ? "Telefonon" : "Phone call"}</option>
                        <option value="email">{lang === "hu" ? "E-mailben" : "E-mail"}</option>
                    </Select>
                </SelectForm>
                <SelectForm variant="outlined" size="small" required span={6}>
                    <InputLabel
                        id="product-label">{lang === "hu" ? "Mivel kapcsolatban érdeklődik?" : "What can we help you with?"}</InputLabel>
                    <Select
                        native
                        name="custom_product"
                        id="custom_product"
                        value={product}
                        onChange={(e) => setProduct(e.target.value)}
                        label={lang === "hu" ? "Mivel kapcsolatban érdeklődik?" : "What can we help you with?"}
                    >
                        <option aria-label="None" value=""/>
                        <option
                            value={"Zero Trust"}>Zero Trust
                        </option>
                        <option
                            value={"Network security"}>{lang === "hu" ? "Hálózatbiztonság" : "Network security"}</option>
                        <option
                            value={"API security"}>{lang === "hu" ? "API-biztonság" : "API security"}</option>
                        <option
                            value={"Identity governance"}>{lang === "hu" ? "Jogosultságkezelés" : "Identity governance"}</option>
                        <option
                            value={"AD security and management"}>{lang === "hu" ? "AD-biztonság és -kezelés" : "AD security and management"}</option>
                        <option
                            value={"Privileged access management"}>{lang === "hu" ? "Kiemeltfelhasználó-kezelés" : "Privileged access management"}</option>
                        <option
                            value={"Log management"}>{lang === "hu" ? "Naplókezelés" : "Log management"}</option>
                        <option
                            value={"IT management"}>{lang === "hu" ? "IT-menedzsment" : "IT management"}</option>
                        <option
                            value={"ICS security"}>{lang === "hu" ? "Ipari biztonság" : "ICS security"}</option>
                        <option
                            value={"IoT security"}>{lang === "hu" ? "IoT-biztonság" : "IoT security"}</option>
                    </Select>
                </SelectForm>
                <SelectForm variant="outlined" size="small" required span={6} disabled={product === ""}>
                    <InputLabel
                        id="subProduct-label">{lang === "hu" ? "Melyik termékkel kapcsolatban érdeklődik?" : "Which product are you interested in?"}</InputLabel>
                    <Select
                        native
                        name="custom_subproduct"
                        id="custom_subproduct"
                        value={subProduct}
                        onChange={(e) => setSubProduct(e.target.value)}
                        label={lang === "hu" ? "Melyik termékkel kapcsolatban érdeklődik?" : "Which product are you interested in?"}
                    >
                        <option aria-label="None" value=""/>
                        {product === "Network security" &&
                        (lang === "hu" ?
                                <>
                                    <option value="Zorp Gateway">Zorp Gateway</option>
                                    <option value="Zorp Malware Detection">Zorp Malware Detection</option>
                                </>
                                :
                                <option value="Proxedo Network Security">Proxedo Network Security</option>
                        )
                        }
                        {(product === "Zero Trust") &&
                        <option value="Zero Trust">Zero Trust</option>
                        }
                        {product === "API security" &&
                        <option value="Proxedo API Lifecycle Platform">Proxedo API Lifecycle Platform</option>
                        }
                        {product === "Identity governance" &&
                        <>
                            <option value="Identity Manager">Identity Manager</option>
                            <option value="Starling Connect">Starling Connect</option>
                            <option value="Starling Two-Factor Authentication">Starling Two-Factor Authentication
                            </option>
                        </>
                        }
                        {product === "AD security and management" &&
                        <>
                            <option value="Active Roles">Active Roles</option>
                            <option value="Starling Connect">Starling Connect</option>
                            <option value="Starling Two-Factor Authentication">Starling Two-Factor Authentication
                            </option>
                        </>
                        }
                        {product === "Privileged access management" &&
                        <>
                            <option value="Safeguard for Privileged Sessions">Safeguard for Privileged Sessions</option>
                            <option value="Safeguard for Privileged Passwords">Safeguard for Privileged Passwords
                            </option>
                            <option value="Safeguard for Privileged Analytics">Safeguard for Privileged Analytics
                            </option>
                            <option
                                value="Safeguard product family">{lang === "hu" ? "Safeguard termékcsalád" : "Safeguard product family"}</option>
                            <option value="Safeguard Authentication Services">Safeguard Authentication Services</option>
                            <option value="Starling Two-Factor Authentication">Starling Two-Factor Authentication
                            </option>
                        </>
                        }
                        {product === "Log management" &&
                        <>
                            <option value="syslog-ng Store Box">Zorp Gateway</option>
                            <option value="syslog-ng Premium Edition">Zorp Malware Detection</option>
                        </>
                        }
                        {product === "IT management" &&
                        <>
                            <option value="Change Auditor">Change Auditor</option>
                            <option value="Enterprise Reporter">Enterprise Reporter</option>
                            <option value="Active Administrator">Active Administrator</option>
                            <option value="GPOADmin">GPOADmin</option>
                            <option value="Recovery Manager">Recovery Manager</option>
                            <option value="Metalogix ControlPoint">Metalogix ControlPoint</option>
                            <option value="Metalogix Backup for Sharepoint">Metalogix Backup for Sharepoint</option>
                            <option value="KACE">KACE</option>
                            <option value="Toad">Toad</option>
                        </>
                        }
                        {product === "ICS security" &&
                        <option
                            value="ICS-security solutions">{lang === "hu" ? "Ipari biztonsági megoldások" : "ICS-security solutions"}</option>
                        }
                        {product === "IoT security" &&
                        <option
                            value="IoT-security solutions">{lang === "hu" ? "IoT-biztonsági megoldások" : "IoT-security solutions"}</option>
                        }
                    </Select>
                </SelectForm>
                <Input
                    name="custom_comment"
                    id="custom_comment"
                    multiline
                    rows={4}
                    variant="outlined"
                    size="small"
                    label={lang === "hu" ? "Üzenet:" : "Message:"}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <input type="hidden" name="campaign_token" value="k"/>
                <input type="hidden" name="thankyou_url"
                       value={`https://balasys.eu/${lang}/forms/message-sent`}/>
                <Col grid span={9} verticalcenter>
                    <CheckboxWrapper>
                        <CheckboxForm
                            color="primary"
                            id="accept_eula"
                            name="accept_eula"
                            inputProps={{'aria-label': 'secondary checkbox'}}
                            required
                        />
                        <Body color="body-text" small>
                            {lang === "hu" ? parse("* Elolvastam és elfogadom az <a href=\"/hu/privacy-policy\" target=\"_blank\" rel=\"noreferrer\" >adatvédelmi szabályzatot</a>!") : parse("* I have read and accept the <a href=\"/en/privacy-policy\" target=\"_blank\" rel=\"noreferrer\" >terms & conditions</a>.")}
                        </Body>
                    </CheckboxWrapper>
                </Col>
                <Col span={3}>
                    <Button
                        id="button"
                        type="submit"
                        filled="true"
                    >
                        {lang === "hu" ? "Küldés" : "Submit"}
                    </Button>
                </Col>
            </FormBenefits>
        </FormBenefitsWrapper>
    )
}

export default FormTrialGR;