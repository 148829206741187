import React from "react";
import {graphql} from "gatsby";
import parse from "html-react-parser";

import Layout from "/src/components/layouts/Layout";
import SectionLayoutGrid from "/src/components/layouts/SectionLayoutGrid";
import FormTrialGR from "../../components/forms/FormTrialGR";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";
import Col from "../../components/styled/grid/Col";
import {Body, Title} from "../../components/styled/typography/Typography";

import circuit from "/static/assets/animations/circuit/circuit01.json";

const RequestTrial = ({location, data}) => {

    const {trial} = data;

    return (
        <Layout lang={trial.lang} seo={trial.SEO}>
            <LottieAnimation animationData={circuit} left="true"/>
            <SectionLayoutGrid customBackground="middle">
                <Col grid center>
                    <Title color="white" marginBottom="normal">
                        {trial.title}
                    </Title>
                    <Body color="body-text">
                        {parse(trial.description)}
                    </Body>
                </Col>
                <Col>
                    <FormTrialGR lang={trial.lang} state={location.state ? location.state.product : null}/>
                </Col>
            </SectionLayoutGrid>
        </Layout>
    )
}

export const query = graphql`
   query GetSingleTrialSS($locale: String) {
         trial: strapiRequestTrials(lang: { eq: $locale }) {
          lang
          title
          description
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
   }
 `

export default RequestTrial;